<template>
    <v-app>
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-md-6 col-lg-6 col-sm-12">
                        <h3>Class Timetable</h3>
                        <router-link :to="{ name: 'dashboard' }"> Dashboard</router-link>
                        <router-link :to="{ name: 'time-table' }"> \ Class Timetable</router-link>
                        \ Manage
                    </div>

                    <div class="col-12 mt-5">
                        <div
                                class="row mb-5"
                                style="background-color: #e7f0f3"
                                v-if="academic_class"
                        >
                            <div class="col-md-4 col-lg-4 col-sm-12">
                                <b>Class/Section Name:</b>
                                {{ academic_class ? academic_class.title || "N/A" : "" }}
                                <br/>
                            </div>
                            <div class="col-md-2 col-lg-2 col-sm-12">
                                <b>Level :</b>
                                {{
                                academic_class && academic_class.level
                                ? academic_class.level.title || "N/A"
                                : ""
                                }}
                                <br/>
                            </div>
                            <div class="col-md-2 col-lg-2 col-sm-12">
                                <b>Program :</b>
                                {{
                                academic_class && academic_class.program
                                ? academic_class.program.title || "N/A"
                                : ""
                                }}
                                <br/>
                            </div>
                            <div class="col-md-2 col-lg-2 col-sm-12">
                                <b>Semester/Year :</b>
                                {{
                                academic_class && academic_class.grade
                                ? academic_class.grade.title || "N/A"
                                : ""
                                }}
                                <br/>
                            </div>
                            <div class="col-md-2 col-lg-2 col-sm-12">
                                <b>Batch :</b>
                                {{
                                academic_class && academic_class.academic_year
                                ? academic_class.academic_year.title || "N/A"
                                : ""
                                }}
                                <br/>
                            </div>
                            <div class="col-md-2 col-lg-2 col-sm-12">
                                <b>Shift :</b> {{ academic_class.shift }} <br/>
                            </div>
                            <div class="col-md-2 col-lg-2 col-sm-12">
                                <b>Created :</b> {{ academicClassTimetable.created_at }} <br/>
                            </div>
                            <div class="col-md-3 col-lg-3 col-sm-12">
                                <b>Last Updated :</b> {{ academicClassTimetable.updated_at }} <br/>
                            </div>
                        </div>
                    </div>

                    <v-col>
                        <v-text-field
                                v-model="academicClassTimetable.title"
                                outlined
                                dense
                                label="Enter Class Timetable Title"
                                clearable
                        ></v-text-field>
                    </v-col>
                    <v-col>
                        <v-btn @click="openWeekdayDialog()" depressed color="blue" class="text-white">
                            {{ academicClassTimetable.id ? "Update" : "Set" }} WeekDays
                        </v-btn>
                    </v-col>
                </div>
                <div
                        class="row"
                        v-if="academicClassTimetable && academicClassTimetable.class_id"
                >
                    <div class="col-md-12">
                        <div class="table table-stripe">
                            <div class="title text-center">Timetable for Week</div>
                            <table class="table table-stripe">
                                <tbody class="">
<!--                                <template v-for="(day,index) in schedule_days">-->
<!--                                    <tr v-if="day.is_working" :key="day.day">-->
<!--                                        <td>-->
<!--                                            {{day.title}} <br>-->
<!--                                            <a href="javascript:void(0)" @click="openTimeTableDialog(day.day)"-->
<!--                                            >Assign</a>-->
<!--                                        </td>-->
<!--                                        <template v-if="day.value.length">-->
<!--                                            <td v-for="(wDay,dayIndex) in day.value" :key="wDay.id">-->
<!--                                                <strong>-->
<!--                                                    {{-->
<!--                                                    `${wDay.simple_start_time ? wDay.simple_start_time : wDay.start_time } - ${wDay.simple_end_time ? wDay.simple_end_time : wDay.end_time}-->
<!--                                                    `-->
<!--                                                    }}</strong-->
<!--                                                >-->

<!--                                                <br/>-->

<!--                                                <strong v-if="!wDay.is_break">-->
<!--                                                    {{ wDay.subject_name }}-->
<!--                                                </strong>-->
<!--                                                <strong v-else> Break </strong>-->
<!--                                                <br/>-->
<!--                                                <strong> {{ wDay.teacher_name || "Not Assigned" }}</strong>-->
<!--                                            </td>-->
<!--                                            <td v-if="day.value.length !=total_col" :colspan="total_col">-->

<!--                                            </td>-->
<!--                                        </template>-->

<!--                                        <td v-else :colspan="total_col">-->
<!--                                            No Assigned-->
<!--                                        </td>-->
<!--                                    </tr>-->
<!--                                    -->
<!--                                </template>-->
                                <tr v-if="academicClassTimetable.is_working_sunday">
                                    <td>Sunday <br> <a href="javascript:void(0)"
                                                       @click="openTimeTableDialog(0)">Manage</a></td>
                                    <td v-for="(sunday,i) in academicClassTimetablePeriods" :key="'sunday'+i"
                                        v-if="sunday.day == 0">
                                        <strong> {{`${sunday.simple_start_time } - ${sunday.simple_end_time} `}}</strong>

                                        <br>

                                        <strong v-if="!sunday.is_break">
                                            {{sunday.subject_name}}
                                        </strong>
                                        <strong v-else>
                                            Break
                                        </strong>
                                        <br>
                                        <strong> {{sunday.teacher_name  || 'Not Assigned'}}</strong>

                                    </td>
                                </tr>
                                <tr v-if="academicClassTimetable.is_working_monday">
                                    <td>Monday <br> <a href="javascript:void(0)"
                                                       @click="openTimeTableDialog(1)">Assign</a></td>
                                    <td v-for="(monday,i) in academicClassTimetablePeriods" :key="'monday'+i"
                                        v-if="monday.day == 1">
                                        <strong> {{`${monday.simple_start_time } - ${monday.simple_end_time} `}}</strong>

                                        <br>

                                        <strong v-if="!monday.is_break">
                                            {{monday.subject_name}}
                                        </strong>
                                        <strong v-else>
                                            Break
                                        </strong>
                                        <br>
                                        <strong> {{monday.teacher_name  || 'Not Assigned'}}</strong>

                                    </td>
                                </tr>
                                <tr v-if="academicClassTimetable.is_working_tuesday">
                                    <td>Tuesday <br> <a href="javascript:void(0)"
                                                        @click="openTimeTableDialog(2)">Assign</a>
                                    </td>
                                    <td v-for="(tuesday,i) in academicClassTimetablePeriods" :key="'tuesday'+i"
                                        v-if="tuesday.day == 2">
                                        <strong> {{`${tuesday.simple_start_time } - ${tuesday.simple_end_time} `}}</strong>

                                        <br>

                                        <strong v-if="!tuesday.is_break">
                                            {{tuesday.subject_name}}
                                        </strong>
                                        <strong v-else>
                                            Break
                                        </strong>
                                        <br>
                                        <strong> {{tuesday.teacher_name || 'Not Assigned'}}</strong>

                                    </td>
                                </tr>

                                <tr v-if="academicClassTimetable.is_working_wednesday">
                                    <td>Wednesday <br> <a href="javascript:void(0)" @click="openTimeTableDialog(3)">Assign</a>
                                    </td>
                                    <td v-for="(wednesday,i) in academicClassTimetablePeriods" :key="'wednesday'+i"
                                        v-if="wednesday.day ==3">
                                        <strong> {{`${wednesday.simple_start_time } - ${wednesday.simple_end_time} `}}</strong>

                                        <br>

                                        <strong v-if="!wednesday.is_break">
                                            {{wednesday.subject_name}}
                                        </strong>
                                        <strong v-else>
                                            Break
                                        </strong>
                                        <br>
                                        <strong> {{wednesday.teacher_name  || 'Not Assigned'}}</strong>

                                    </td>

                                </tr>
                                <tr v-if="academicClassTimetable.is_working_thursday">
                                    <td>Thursday <br> <a href="javascript:void(0)" @click="openTimeTableDialog(4)">Assign</a>
                                    </td>
                                    <td v-for="(thursday,i) in academicClassTimetablePeriods" :key="'thursday'+i"
                                        v-if="thursday.day == 4">
                                        <strong> {{`${thursday.simple_start_time } - ${thursday.simple_end_time} `}}</strong>

                                        <br>

                                        <strong v-if="!thursday.is_break">
                                            {{thursday.subject_name}}
                                        </strong>
                                        <strong v-else>
                                            Break
                                        </strong>
                                        <br>
                                        <strong> {{thursday.teacher_name  || 'Not Assigned'}}</strong>
                                    </td>
                                </tr>
                                <tr v-if="academicClassTimetable.is_working_friday">
                                    <td>Friday <br> <a href="javascript:void(0)"
                                                       @click="openTimeTableDialog(5)">Assign</a></td>
                                    <td v-for="(friday,i) in academicClassTimetablePeriods" :key="'friday'+i"
                                        v-if="friday.day == 5">
                                        <strong> {{`${friday.simple_start_time } - ${friday.simple_end_time} `}}</strong>

                                        <br>

                                        <strong v-if="!friday.is_break">
                                            {{friday.subject_name}}
                                        </strong>
                                        <strong v-else>
                                            Break
                                        </strong>
                                        <br>
                                        <strong> {{friday.teacher_name  || 'Not Assigned'}}</strong>

                                    </td>
                                </tr>
                                <tr v-if="academicClassTimetable.is_working_saturday">
                                    <td>Saturday <br> <a href="javascript:void(0)" @click="openTimeTableDialog(6)">Assign</a>
                                    </td>
                                    <td v-for="(saturday,i) in academicClassTimetablePeriods" :key="'saturday'+i"
                                        v-if="saturday.day == 6">
                                        <strong> {{`${saturday.simple_start_time } - ${saturday.simple_end_time} `}}</strong>

                                        <br>

                                        <strong v-if="!saturday.is_break">
                                            {{saturday.subject_name}}
                                        </strong>
                                        <strong v-else>
                                            Break
                                        </strong>
                                        <br>
                                        <strong> {{saturday.teacher_name  || 'Not Assigned'}}</strong>

                                    </td>
                                </tr>

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <v-dialog v-model="weekdayDialog" max-width="600">
                <v-card>
                    <v-card-title> Class Days</v-card-title>
                    <v-card-text>
                        <div class="table table-stripe">
                            <table class="table table-stripe">
                                <thead>
                                <th>Set Class</th>
                                <th>Day</th>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>
                                        <v-checkbox
                                                v-model="academicClassTimetable.is_working_sunday"
                                        ></v-checkbox>
                                    </td>
                                    <td>Sunday</td>
                                </tr>
                                <tr>
                                    <td>
                                        <v-checkbox
                                                v-model="academicClassTimetable.is_working_monday"
                                        ></v-checkbox>
                                    </td>
                                    <td>Monday</td>
                                </tr>
                                <tr>
                                    <td>
                                        <v-checkbox
                                                v-model="academicClassTimetable.is_working_tuesday"
                                        ></v-checkbox>
                                    </td>
                                    <td>Tuesday</td>
                                </tr>
                                <tr>
                                    <td>
                                        <v-checkbox
                                                v-model="academicClassTimetable.is_working_wednesday"
                                        ></v-checkbox>
                                    </td>
                                    <td>Wednesday</td>
                                </tr>
                                <tr>
                                    <td>
                                        <v-checkbox
                                                v-model="academicClassTimetable.is_working_thursday"
                                        ></v-checkbox>
                                    </td>
                                    <td>Thursday</td>
                                </tr>
                                <tr>
                                    <td>
                                        <v-checkbox
                                                v-model="academicClassTimetable.is_working_friday"
                                        ></v-checkbox>
                                    </td>
                                    <td>Friday</td>
                                </tr>
                                <tr>
                                    <td>
                                        <v-checkbox
                                                v-model="academicClassTimetable.is_working_saturday"
                                        ></v-checkbox>
                                    </td>
                                    <td>Saturday</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </v-card-text>
                    <v-card-actions>
                        <v-col class="text-right">
                            <v-btn @click="saveOrUpdare()" depressed color="blue" class="text-white">
                                Save
                            </v-btn>
                        </v-col>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <v-dialog v-model="timeTableDialog" max-width="1500">
                <v-card>
                    <v-card-title> Manage classes & Bbreak timetable</v-card-title>
                    <v-card-text>
                        <div class="table table-stripe">
                            <table class="table table-stripe">
                                <thead>
                                <th>Break time</th>
                                <th>Subject</th>
                                <th>Teacher</th>
                                <th>Start time</th>
                                <th>End time</th>
                                <th>Running mode</th>
                                <th>Room</th>
                                <th>Class URL</th>
                                <th>Online class type</th>
                                <th>Actions</th>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>
                                        <v-checkbox v-model="academicClassTimetablePeriod.is_break"
                                        >Is break
                                        </v-checkbox>
                                    </td>
                                    <td>
                                        <v-select
                                                v-if="!academicClassTimetablePeriod.is_break"
                                                outlined
                                                dense
                                                v-model="academicClassTimetablePeriod.subject_id"
                                                :items="assigned_courses"
                                                label="Select Subject"
                                                item-value="id"
                                                item-text="title"
                                                return-object
                                                @change="getAssignedTeachers"
                                        >
                                        </v-select>
                                        <strong v-else> Break </strong>
                                    </td>
                                    <td>
                                        <v-select
                                                v-if="!academicClassTimetablePeriod.is_break"
                                                outlined
                                                dense
                                                v-model="academicClassTimetablePeriod.teacher_id"
                                                :items="assigned_teachers"
                                                label="Select Teacher"
                                                item-value="teacher.id"
                                                item-text="teacher.full_name"
                                                return-object
                                        >
                                        </v-select>
                                        <strong v-else> Break </strong>
                                    </td>
                                    <td>
                                        <v-text-field
                                                outlined
                                                dense
                                                v-
                                                v-model="academicClassTimetablePeriod.start_time"
                                                type="time"
                                        ></v-text-field>
                                        <span
                                                class="text-danger"
                                                v-if="$v.academicClassTimetablePeriod.start_time.$error"
                                        >Start Time is required</span
                                        >
                                    </td>
                                    <td>
                                        <v-text-field
                                                outlined
                                                dense
                                                v-
                                                v-model="academicClassTimetablePeriod.end_time"
                                                type="time"
                                        ></v-text-field>
                                        <span
                                                class="text-danger"
                                                v-if="$v.academicClassTimetablePeriod.end_time.$error"
                                        >End Time is required</span
                                        >
                                    </td>
                                    <td>
                                        <v-select
                                                :items="running_mode"
                                                item-value="value"
                                                item-text="title"
                                                v-model="academicClassTimetablePeriod.running_mode"
                                                outlined
                                                dense
                                                placeholder="Class Type "
                                                label="Running Mode"
                                        ></v-select>
                                    </td>
                                    <td>
                                        <v-select
                                                :items="rooms"
                                                v-if="academicClassTimetablePeriod.running_mode == 'campus'"
                                                item-text="title"
                                                item-value="id"
                                                v-model="academicClassTimetablePeriod.room_id"
                                                outlined
                                                dense
                                                placeholder="Class Type "
                                                label="Room"
                                        ></v-select>
                                        <span v-else>N/A</span>
                                    </td>
                                    <td>
                                        <v-text-field
                                                v-if="academicClassTimetablePeriod.running_mode == 'online'"
                                                outlined
                                                dense
                                                v-model="academicClassTimetablePeriod.online_class_url"
                                                placeholder="Online Class Url"
                                                label="Online Class Url"
                                        ></v-text-field>
                                        <span v-else>N/A</span>
                                    </td>
                                    <td>
                                        <v-select
                                                v-if="academicClassTimetablePeriod.running_mode == 'online'"
                                                :items="online_class_type"
                                                item-value="value"
                                                item-text="title"
                                                v-model="academicClassTimetablePeriod.online_class_type"
                                                outlined
                                                dense
                                                placeholder="Class Type "
                                                label="Class Type"
                                        ></v-select>
                                        <span v-else>N/A</span>
                                    </td>
                                    <td>
                                        <v-btn
                                                v-if="academicClassTimetablePeriod.id"
                                                @click="editPeriod()"
                                                depressed
                                                color="blue"
                                                class="text-white"
                                        >
                                            Edit Period
                                        </v-btn>
                                        <v-btn
                                                v-else
                                                @click="addTimeTable"
                                                depressed
                                                color="blue"
                                                class="text-white"
                                        >
                                            Add Period
                                        </v-btn>
                                    </td>
                                </tr>

                                <tr
                                        v-for="(item, i) in academicClassTimetablePeriods"
                                        :key="item.day"
                                        v-if="item.day == currentDay"
                                >
                                    <td v-if="!item.is_break" colspan="2">
                                        {{ item.subject_name }}
                                    </td>
                                    <td v-if="!item.is_break">
                                        {{ item.teacher_name }}
                                    </td>
                                    <td v-else colspan="3">
                                        <strong>Break</strong>
                                    </td>
                                    <td>
                                        {{ item.simple_start_time ? item.simple_start_time : item.start_time }}
                                    </td>
                                    <td>
                                        {{ item.simple_end_time  ? item.simple_end_time : item.end_time}}
                                    </td>
                                    <td>
                                        {{ item.rooms ? item.rooms.title : "N/A" }}
                                        <br/>
                                        Room no {{ item.rooms ? item.rooms.room_number : "N/A" }}
                                    </td>
                                    <td>
                                        {{ item.running_mode }}
                                    </td>
                                    <td>
                                        {{ item.online_class_url }}
                                    </td>
                                    <td>
                                        {{ item.online_class_type }}
                                    </td>
                                    <td>
                                        <v-btn @click="editTimeTable(item)" icon color="blue">
                                            <v-icon>mdi-pencil</v-icon>
                                        </v-btn>
                                        <v-btn @click="removeTimeTable(i)" icon color="red">
                                            <v-icon>mdi-delete</v-icon>
                                        </v-btn>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </v-card-text>
                    <v-card-actions>
                        <v-col class="text-right">
                            <v-btn
                                    depressed
                                    @click="saveTimeTablePeriod()"
                                    color="blue"
                                    class="text-white"
                            >
                                Save
                            </v-btn>
                        </v-col>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </div>
    </v-app>
</template>

<script>
    import AcademicClassService from "@/core/services/academic-class/AcademicClassService";
    import UserService from "@/core/services/user/UserService";
    import AcademicClassTeacherService from "@/core/services/academic-class/class-teacher/AcademicClassTeacherService";
    import AcademicCLassTimetableService from "@/core/services/academicClassTimetable/AcademicCLassTimetableService";
    import LevelService from "@/core/services/level/LevelService";
    import ProgramService from "@/core/services/level/program/ProgramService";
    import AcademicYearService from "@/core/services/academicYear/AcademicYearService";
    import GradeLevelService from "@/core/services/gradeLevel/GradeLevelService";

    import AcademicCLassTimetablePeriodService
        from "@/core/services/academicClassTimetablePeriod/AcademicCLassTimetablePeriodService";
    import RoomsService from "@/core/services/rooms/RoomsService";
    import {required} from "vuelidate/lib/validators";

    const levelService = new LevelService();
    const academicYearService = new AcademicYearService();
    const gradeLevelService = new GradeLevelService();

    const programService = new ProgramService();
    const academicClassTimetable = new AcademicCLassTimetableService();
    const academicClassService = new AcademicClassService();
    const userService = new UserService();
    const academicCLassTimetablePeriod = new AcademicCLassTimetablePeriodService();
    const academicClassTeacherService = new AcademicClassTeacherService();
    const roomService = new RoomsService();
    export default {
        name: "TimeTablePeriods",
        validations: {
            academicClassTimetablePeriod: {
                start_time: {required},
                end_time: {required},
            },
        },
        data() {
            return {
                time: null,
                currentDay: null,
                start_time: false,
                end_time: false,
                academicClassTimetable: {
                    class_id: null,
                    title: null,
                    is_working_sunday: null,
                    is_working_monday: null,
                    is_working_tuesday: null,
                    is_working_wednesday: null,
                    is_working_thursday: null,
                    is_working_friday: null,
                    is_working_saturday: null,
                    is_active: 0,
                },
                academicClassTimetablePeriod: {
                    timetable_id: null,
                    day: null,
                    subject_id: null,
                    subject_name: null,
                    teacher_id: null,
                    teacher_name: null,
                    start_time: null,
                    end_time: null,
                    running_mode: "campus",
                    is_break: 0,
                },
                checkAvailiblity: {
                    teacher_id: null,
                    start_time: null,
                    end_time: null,
                },
                academicClassTimetablePeriods: [],
                subjects: [],
                assigned_courses: [],
                weekdayDialog: false,
                timeTableDialog: false,
                assignClassDialog: false,
                academic_classes: [],
                academic_class: {
                    level_id: null,
                    program_id: null,
                    grade_id: null,
                    shift: null,
                    title: null,
                    is_active: true,
                },
                rooms: [],
                week_days: [
                    {
                        day: 0,
                        title: 'Sunday',
                        value: [],
                    }, {
                        day: 1,
                        title: 'Monday',
                        value: [],
                    },
                    {
                        day: 2,
                        title: 'Tuesday',
                        value: [],
                    }, {
                        day: 3,
                        title: 'Wednesday',
                        value: [],
                    },
                    {
                        day: 4,
                        title: 'Thursday',
                        value: [],
                    }, {
                        day: 5,
                        title: 'Friday',
                        value: [],
                    },
                    {
                        day: 6,
                        title: 'Saturday',
                        value: [],
                    },
                ],
                running_mode: [
                    {
                        title: "Online",
                        value: "online",
                    },
                    {
                        title: "Campus",
                        value: "campus",
                    },
                ],
                online_class_type: [
                    {
                        title: "Zoom",
                        value: "zoom",
                    },
                    {
                        title: "Microsoft Team",
                        value: "teams",
                    },
                    {
                        title: "Google Meet",
                        value: "meet",
                    },
                ],
                assign_course_teachers: [],
                class: null,
                class_teachers: [],
                assigned_teachers: [],
                loading: false,
                users: [],
                time_tables: [],
                selected: [],
            };
        },
        computed: {
            time_table_id() {
                return this.$route.params.time_table_id;
            },

            currentUser() {
                return this.$store.getters.currentUser;
            },

            schedule_days() {
                if (this.academicClassTimetablePeriods && this.academicClassTimetablePeriods.length) {
                    let formatedDays = this.academicClassTimetablePeriods.map(day => {
                        day.day = parseInt(day.day);
                        return day;
                    })

                    let sunday = formatedDays.filter(obj => {
                        return obj.day == 0;
                    });
                    let monday = formatedDays.filter(obj => {
                        return obj.day == 1;
                    });
                    let tuesday = formatedDays.filter(obj => {
                        return obj.day == 2;
                    });
                    let wednesday = formatedDays.filter(obj => {
                        return obj.day == 3;
                    });
                    let thursday = formatedDays.filter(obj => {
                        return obj.day == 4;
                    });
                    let friday = formatedDays.filter(obj => {
                        return obj.day == 5;
                    });
                    let saturday = formatedDays.filter(obj => {
                        return obj.day == 6;
                    });
                    this.week_days.forEach(element => {
                        switch (element.day) {
                            case 0:
                                this.academicClassTimetable.is_working_sunday ? element.is_working = true : element.is_working = false;
                                element.value = sunday;
                                break;
                            case 1:
                                this.academicClassTimetable.is_working_monday ? element.is_working = true : element.is_working = false;
                                element.value = monday;
                                break;
                            case 2:
                                this.academicClassTimetable.is_working_tuesday ? element.is_working = true : element.is_working = false;
                                element.value = tuesday;
                                break;
                            case 3:
                                this.academicClassTimetable.is_working_wednesday ? element.is_working = true : element.is_working = false;
                                element.value = wednesday;
                                break;
                            case 4:
                                this.academicClassTimetable.is_working_thursday ? element.is_working = true : element.is_working = false;
                                element.value = thursday;
                                break;
                            case 5:
                                this.academicClassTimetable.is_working_friday ? element.is_working = true : element.is_working = false;
                                element.value = friday;
                                break;
                            case 6:
                                this.academicClassTimetable.is_working_saturday ? element.is_working = true : element.is_working = false;
                                element.value = saturday;
                                break;
                            default:
                                return null
                        }

                    })
                    return this.week_days;
                } else {
                    this.week_days.forEach(element => {
                        switch (element.day) {
                            case 0:
                                this.academicClassTimetable.is_working_sunday ? element.is_working = true : element.is_working = false;
                                break;
                            case 1:
                                this.academicClassTimetable.is_working_monday ? element.is_working = true : element.is_working = false;
                                break;
                            case 2:
                                this.academicClassTimetable.is_working_tuesday ? element.is_working = true : element.is_working = false;
                                break;
                            case 3:
                                this.academicClassTimetable.is_working_wednesday ? element.is_working = true : element.is_working = false;
                                break;
                            case 4:
                                this.academicClassTimetable.is_working_thursday ? element.is_working = true : element.is_working = false;
                                break;
                            case 5:
                                this.academicClassTimetable.is_working_friday ? element.is_working = true : element.is_working = false;
                                break;
                            case 6:
                                this.academicClassTimetable.is_working_saturday ? element.is_working = true : element.is_working = false;
                                break;
                            default:
                                return null
                        }

                    })
                    return this.week_days;
                }
            },
            total_col() {
                let col = 0;
                this.week_days.forEach(day => {
                    if (day.value.length > col)
                        col = day.value.length;
                })
                return col;
            }
        },
        mounted() {
            this.getTimeTable();
            this.getRooms();
        },
        methods: {
            getRooms() {
                roomService
                    .paginate()
                    .then((response) => {
                        this.rooms = response.data.data;
                    })
                    .catch((error) => {
                    });
            },
            getAcademicClasses() {
                academicClassService
                    .getByProgramLevel(
                        this.search.academic_year_id,
                        this.search.program_id,
                        this.search.grade_id
                    )
                    .then((response) => {
                        this.academic_classes = response.data;
                    });
            },
            openWeekdayDialog() {
                this.weekdayDialog = true;
            },
            openTimeTableDialog(day) {
                this.currentDay = day;
                this.academicClassTimetablePeriod.day = this.currentDay;
                this.timeTableDialog = true;
            },

            getClass() {
                this.academicClassTimetable.class_id = this.search.id;
            },
            addTimeTable() {
                // this.checkAvailiblity.teacher_id = this.academicClassTimetablePeriod.teacher_id.id ? this.academicClassTimetablePeriod.teacher_id.id : null;
                // this.checkAvailiblity.start_time = this.academicClassTimetablePeriod.start_time;
                // this.checkAvailiblity.end_time = this.academicClassTimetablePeriod.end_time;
                //
                // this.checkAvailablity();

                this.$v.$touch();
                if (this.$v.academicClassTimetablePeriod.$error) {
                    setTimeout(() => {
                        this.$v.$reset();
                    }, 3000);
                } else {
                    if (!this.academicClassTimetablePeriod.is_break) {
                        let subjectId = this.academicClassTimetablePeriod.subject_id.id;
                        let subjectName = this.academicClassTimetablePeriod.subject_id.title;
                        let teacherId = this.academicClassTimetablePeriod.teacher_id
                            ? this.academicClassTimetablePeriod.teacher_id.teacher.id
                            : null;
                        let teacherName = this.academicClassTimetablePeriod.teacher_id
                            ? this.academicClassTimetablePeriod.teacher_id.teacher.full_name
                            : "N/A";
                        this.academicClassTimetablePeriod.subject_id = subjectId;
                        this.academicClassTimetablePeriod.teacher_id = teacherId;
                        this.academicClassTimetablePeriod.subject_name = subjectName;
                        this.academicClassTimetablePeriod.teacher_name = teacherName;
                    }
                    this.academicClassTimetablePeriod.day = this.currentDay;
                    this.academicClassTimetablePeriods.push(this.academicClassTimetablePeriod);
                    this.resetTimeTablePeriod();
                    this.$v.$reset();
                }
            },
            removeTimeTable(index) {
                if (this.academicClassTimetablePeriods[index].id) {
                    this.deleteassignPeriod(this.academicClassTimetablePeriods[index].id);
                }
                this.academicClassTimetablePeriods.splice(index, 1);
            },
            editTimeTable(item) {
                this.academicClassTimetablePeriod = item;
            },   deleteassignPeriod(id) {
                this.$confirm({
                    message: `Are you sure?`,
                    button: {
                        no: "No",
                        yes: "Yes"
                    },
                    /**
                     * Callback Function
                     * @param {Boolean} confirm
                     */
                    callback: confirm => {
                        if (confirm) {
                            academicCLassTimetablePeriod
                                .delete(this.academicClassTimetable.id, id)
                                .then(response => {
                                    this.$snotify.success("Information deleted");

                                    this.getAcademicTimetablePeriod();
                                })
                                .catch(error => {
                                });
                        }
                    }
                });
            },

            resetTimeTablePeriod() {
                this.academicClassTimetablePeriod = {
                    timetable_id: this.academicClassTimetable.id,
                    day: null,
                    subject_id: null,
                    subject_name: null,
                    teacher_id: null,
                    teacher_name: null,
                    start_time: null,
                    end_time: null,
                    is_break: null,
                };
            },
            resetTimeTable() {
                this.academicClassTimetable = {
                    class_id: null,
                    title: null,
                    is_working_sunday: null,
                    is_working_monday: null,
                    is_working_tuesday: null,
                    is_working_wednesday: null,
                    is_working_thursday: null,
                    is_working_friday: null,
                    is_working_saturday: null,
                    is_active: 0,
                };
            },

            getUsers() {
                userService.paginate(this.search, this.page).then((response) => {
                    this.users = response.data.data;
                });
            },
            getAcademicTimetablePeriod() {
                academicCLassTimetablePeriod
                    .paginate(this.academicClassTimetable.id)
                    .then((resources) => {
                        this.academicClassTimetablePeriods = resources.data.data;
                    })
                    .catch((err) => {
                    });
            },
            checkAvailablity() {
                academicCLassTimetablePeriod
                    .checkAvailablity(this.academicClassTimetable.id, this.checkAvailiblity)
                    .then((resources) => {
                    })
                    .catch((err) => {
                    });
            },
            getAssignedClassCourseTeacher() {
                academicClassTeacherService.paginate().then((response) => {
                    this.assign_course_teachers = response.data.data;
                    const teachersIds = this.assign_course_teachers.filter((item) => {
                        return item.teacher_id == 938;
                    });
                });
            },
            getAllTimeTable() {
                this.$bus.emit("toggleLoader");
                academicClassTimetable
                    .paginate()
                    .then((response) => {
                        this.time_tables = response.data.data;
                        this.$bus.emit("toggleLoader");
                    })
                    .catch((error) => {
                        this.$bus.emit("toggleLoader");
                    });
            },
            getTimeTable() {
                this.$bus.emit("toggleLoader");
                academicClassTimetable
                    .show(this.time_table_id)
                    .then((response) => {
                        this.academicClassTimetable = response.data.academicClassTimetable;
                        this.academic_class = response.data.academicClassTimetable.academic_class;
                        this.getAcademicTimetablePeriod();
                        this.getAssignedCourses();
                        // this.getAssignedTeachers();
                        this.$bus.emit("toggleLoader");
                    })
                    .catch((error) => {
                        this.resetTimeTable();
                        this.resetTimeTablePeriod();
                        this.$bus.emit("toggleLoader");
                    });
            },
            createTimeTable() {
                this.academicClassTimetable.class_id = this.academic_class.id;
                academicClassTimetable
                    .store(this.academicClassTimetable)
                    .then((response) => {
                        this.academicClassTimetable = response.data.status;
                        this.weekdayDialog = false;
                        this.academicClassTimetablePeriod.timetable_id = this.academicClassTimetable.id;
                        this.$snotify.success("Information updated");
                    })
                    .catch((err) => {
                    });
            },
            saveOrUpdare(){
              if (this.academicClassTimetable.id)
                  this.updateTimeTable();
              else
                  this.createTimeTable();
            },
            updateTimeTable() {
                this.academicClassTimetable.class_id = this.academic_class.id;
                academicClassTimetable
                    .update(this.academicClassTimetable.id,this.academicClassTimetable)
                    .then((response) => {
                        // this.academicClassTimetable = response.data.status;
                        this.weekdayDialog = false;
                        // this.academicClassTimetablePeriod.timetable_id = this.academicClassTimetable.id;
                        this.getTimeTable();
                        this.getRooms();
                        this.$snotify.success("Information updated");
                    })
                    .catch((err) => {
                    });
            },
            saveTimeTablePeriod() {
                this.academicClassTimetablePeriods = this.academicClassTimetablePeriods.map(
                    (period) => {
                        delete period.simple_start_time;
                        delete period.simple_end_time;
                        delete period.rooms;
                        if (!Object.keys(period).includes("id")) return period;
                    }
                );
                academicCLassTimetablePeriod
                    .store(this.academicClassTimetable.id, this.academicClassTimetablePeriods)
                    .then((response) => {
                        this.$snotify.success("Information updated");
                        this.timeTableDialog = false;
                        this.getAcademicTimetablePeriod();
                    })
                    .catch((err) => {
                    });
            },
            editPeriod() {
                academicCLassTimetablePeriod
                    .update(
                        this.academicClassTimetable.id,
                        this.academicClassTimetablePeriod.id,
                        this.academicClassTimetablePeriod
                    )
                    .then((response) => {
                        this.$snotify.success("TimeTable Information updated");
                        this.resetTimeTablePeriod();
                    })
                    .catch((err) => {
                        this.$snotify.error("Error Occured");
                    });
            },
            save() {
                this.assign_course_teacher.course_id = this.academic_classes[this.selected].id;
                if (
                    this.checkReapeatTeacherClass(
                        this.assign_course_teacher.teacher_id,
                        this.assign_course_teacher.course_id
                    )
                ) {
                    this.$confirm({
                        message: `Previous courses & teachers detected, confirm reassign?`,
                        button: {
                            no: "No",
                            yes: "Yes",
                        },
                        /**
                         * Callback Function
                         * @param {Boolean} confirm
                         */
                        callback: (confirm) => {
                            if (confirm) {
                                academicClassTeacherService
                                    .create(this.assign_course_teacher)
                                    .then((response) => {
                                        this.$snotify.success("Information updated");
                                        this.$v.$reset();
                                        this.getAssignedClassCourseTeacher();
                                    });
                            }
                        },
                    });
                } else {
                    academicClassTeacherService
                        .create(this.assign_course_teacher)
                        .then((response) => {
                            this.$snotify.success("Information updated");
                            this.getAssignedClassCourseTeacher();
                        });
                }
            },

            getAssignedTeachers() {
                academicClassService
                    .getAssignedTeachersByCourse(this.academic_class.id,this.academicClassTimetablePeriod.subject_id.id)
                    .then((response) => {
                        this.assigned_teachers = response.data.teachers;
                    });
            },
            getAssignedCourses() {
                academicClassService.getAssignedCourses(this.academic_class.id).then((response) => {
                    this.assigned_courses = response.data.courses;
                });
            },

            deleteTimeTable(id) {
                this.$confirm({
                    message: `Are You Suer?`,
                    button: {
                        no: "No",
                        yes: "Yes",
                    },
                    /**
                     * Callback Function
                     * @param {Boolean} confirm
                     */
                    callback: (confirm) => {
                        if (confirm) {
                            academicClassTimetable.delete(id).then((response) => {
                                this.$snotify.success("Information updated");
                                this.getAllTimeTable();
                            });
                        }
                    },
                });
            },
        },
    };
</script>

<style scoped></style>
